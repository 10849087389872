"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateSubmitText = exports.updateWishes = void 0;
var ticket_1 = require("./ticket");
var steps_1 = require("./steps");
var functions_1 = require("../functions");
function default_1($) {
    if (!$('#atlasOrderForm').length) {
        return;
    }
    (0, ticket_1.initOrderTicket)($);
    (0, steps_1.initOrderSteps)($);
    $('.c-pricing-option__content').on('click', function (e) {
        $(e.currentTarget).find('.c-pricing-option__cta').click();
    });
    $('#order_atlas_report_reportType').on('change', function (event) {
        $('.is-selected').removeClass('is-selected');
        var reportType = $(event.target).val();
        $("[data-type=\"".concat(reportType, "\"]")).addClass('is-selected');
        updateWishes($, reportType);
        (0, ticket_1.updateOrderTicket)($, null, null, reportType);
        updateSubmitText($, reportType);
    });
    $('.c-pricing-option__cta').on('click', function (event) {
        var reportType = $(event.target).closest('.c-pricing-block__item').data('type');
        var reportPrice = $(event.target).closest('.c-pricing-block__item').data('price');
        $('#order_atlas_report_reportType').val(reportType).change();
        $("[data-type=\"".concat(reportType, "\"]")).addClass('is-selected');
        updateWishes($, reportType);
        (0, ticket_1.updateOrderTicket)($, null, null, reportType, reportPrice);
        updateSubmitText($, reportType);
        return (0, functions_1.nothing)(event);
    });
    $('#order_atlas_report_reportSubject').on('change', function (event) {
        var subject = $(event.target).find('option:selected').text();
        (0, ticket_1.updateOrderTicket)($, subject);
    });
    $('#order_atlas_report_reportLocation').on('change', function (event) {
        var location = $(event.target).find('option:selected').text();
        (0, ticket_1.updateOrderTicket)($, null, location);
    });
}
exports.default = default_1;
function updateWishes($, reportType) {
    var wishes = $('#report-custom-wishes');
    wishes.removeClass('u-hidden');
    var shown = reportType === 'custom';
    $(wishes).find('textarea').prop('required', shown);
    wishes.toggleClass('u-hidden', !shown);
}
exports.updateWishes = updateWishes;
function updateSubmitText($, reportType) {
    var custom = $('#order-custom');
    var regular = $('#order-regular');
    var shown = reportType === 'custom';
    custom.toggleClass('u-hidden', !shown);
    regular.toggleClass('u-hidden', shown);
}
exports.updateSubmitText = updateSubmitText;
