"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddressTypeChanged = void 0;
var AddressTypeChanged = /** @class */ (function () {
    function AddressTypeChanged(addressType) {
        this.addressType = addressType;
    }
    return AddressTypeChanged;
}());
exports.AddressTypeChanged = AddressTypeChanged;
