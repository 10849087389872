<template>
  <div class="t-heading t-heading--lg" v-if="label">
    <h1>${ label }</h1>
  </div>
  <div class="l-row c-form-wrapper">
    <div class="l-col l-col--12 l-col--7-md">
      <Choice
          v-for="(node, index) in currentPath"
          :key="index"
          :choice="node.choice"
          :selected="node.selected"
          @change="handleChange(index, $event)"
      />

        <div class="c-form-footer" v-if="allSelected">
          <div class="c-form-footer__description">&nbsp;</div>
          <div class="c-form-footer__action">
            <a
                v-if="allSelected"
                class="c-button c-button--go c-button--cta-primary"
                :href="nextButtonHref()"
            >
              <span class="c-button__text ">Naar gegevens</span>
            </a>
          </div>
        </div>
    </div>
  </div>
  <Ticket :price="totalPrice" :priceInfo="priceInfo" :currentPath="this.currentPath.filter(node => (node.selected && node.choice.omitSubscriptionIncludes !== true))"></Ticket>
</template>

<script>
import Ticket from './MembershipTicket.vue';
import Choice from './MembershipChoice.vue';

// When changing, also change techfest_enabled in templates/public/agconnect-new/membership/sign-up/default.html.twig
const askTechFest = false;

const decisionTree = [
  {
    label: 'Voor wie wil je een abonnement?',
    key: 'subscriber-type',
    options: [
      { label: "Mijzelf", key: 'personal' },
      { label: "Mijn bedrijf of team", key: 'corporate' },
    ],
    omitSubscriptionIncludes: true,
  },
  {
    label: 'Voor hoeveel collega\'s?',
    key: 'seats',
    options: [
      { label: "Team", description: '10 collega\'s, 1 magazine per maand', key: '10', price:  67_95 },
      { label: "Business", description: '11-25 collega\'s, 3 magazines per maand', key: '25', price: 134_95 },
      { label: "Enterprise", description: '26-50 collega\'s, 5 magazines per maand', key: '50', price: 224_95 },
    ],
    conditions: {'subscriber-type': 'corporate'},
  },
  {
    label: 'Hoe lees je het liefst?',
    key: 'delivery-type',
    options: [
      { label: "Digitaal + magazine", key: 'online_and_magazine', price: 11_75 },
      { label: "Digitaal", key: 'online_only', price: 8_25 },
    ],
    conditions: {'subscriber-type': 'personal'},
  },
];

let personalOnlineOnlyNoTechFestOptions = [
  { label: "Maandelijks", key: 'monthly', description: 'Je ontvangt geen korting, maar je kan wel maandelijks je abonnement opzeggen.', price: 5_50 },
  { label: "1 jaar", key: '1_year', price: 3_95 },
  { label: "2 jaar", key: '2_year', price: 0 },
];
let personalOnlineOnlyWithTechFestOptions = [
  { label: "1 jaar", key: '1_year', price: 3_95 },
  { label: "2 jaar", key: '2_year', price: 0 },
];

if (askTechFest) {
  decisionTree.push(
    {
      label: 'Wil je een ticket voor AI Day?',
      key: 'tech-fest',
      options: [
        { label: "Ja, ik wil een ticket voor AI Day", key: 'include_tech_fest', price: 5_44 },
        { label: "Nee, ik wil geen ticket voor AI Day", key: 'no_tech_fest', },
      ],
      conditions: {'subscriber-type': 'personal'},
    },
    {
      label: 'Hoe lang wil je het abonnement?',
      key: 'subscription-duration',
      options: personalOnlineOnlyNoTechFestOptions,
      conditions: {'subscriber-type': 'personal', 'delivery-type': 'online_only', 'tech-fest': 'no_tech_fest'},
    },
    {
      label: 'Hoe lang wil je het abonnement?',
      key: 'subscription-duration',
      options: personalOnlineOnlyWithTechFestOptions,
      conditions: {'subscriber-type': 'personal', 'delivery-type': 'online_only', 'tech-fest': 'include_tech_fest'},
    },
  );
} else {
  decisionTree.push({
    label: 'Hoe lang wil je het abonnement?',
    key: 'subscription-duration',
    options: personalOnlineOnlyNoTechFestOptions,
    conditions: {'subscriber-type': 'personal', 'delivery-type': 'online_only'},
  });
}

decisionTree.push(
  {
    label: 'Hoe lang wil je het abonnement?',
    key: 'subscription-duration',
    options: [
      { label: "1 jaar", key: '1_year', price: 3_95 },
      { label: "2 jaar", key: '2_year', price: 0 },
    ],
    conditions: {'subscriber-type': 'personal', 'delivery-type': 'online_and_magazine'},
  },
  {
    label: 'Hoe lang wil je het abonnement?',
    key: 'subscription-duration',
    options: [
      { label: "1 jaar", key: '1_year', price: 7_00 },
      { label: "2 jaar", key: '2_year', price: 0 },
    ],
    conditions: {'subscriber-type': 'corporate', 'seats': '10'},
  },
  {
    label: 'Hoe lang wil je het abonnement?',
    key: 'subscription-duration',
    options: [
      { label: "1 jaar", key: '1_year', price: 15_00 },
      { label: "2 jaar", key: '2_year', price: 0 },
    ],
    conditions: {'subscriber-type': 'corporate', 'seats': '25'},
  },
  {
    label: 'Hoe lang wil je het abonnement?',
    key: 'subscription-duration',
    options: [
      { label: "1 jaar", key: '1_year', price: 25_00 },
      { label: "2 jaar", key: '2_year', price: 0 },
    ],
    conditions: {'subscriber-type': 'corporate', 'seats': '50'},
  },
);

const subscriptionCodes = {
  // Techfest enabled
  'personal--online_only--no_tech_fest--monthly': 'ONLINE_ONLY_NO_TECH_FEST_MONTHLY',
  'personal--online_only--no_tech_fest--1_year': 'ONLINE_ONLY_NO_TECH_FEST_1YEAR',
  'personal--online_only--no_tech_fest--2_year': 'ONLINE_ONLY_NO_TECH_FEST_2YEAR',
  'personal--online_only--include_tech_fest--1_year': 'ONLINE_ONLY_INCLUDE_TECH_FEST_1YEAR',
  'personal--online_only--include_tech_fest--2_year': 'ONLINE_ONLY_INCLUDE_TECH_FEST_2YEAR',
  'personal--online_and_magazine--no_tech_fest--1_year': 'ONLINE_AND_MAGAZINE_NO_TECH_FEST_1YEAR',
  'personal--online_and_magazine--no_tech_fest--2_year': 'ONLINE_AND_MAGAZINE_NO_TECH_FEST_2YEAR',
  'personal--online_and_magazine--include_tech_fest--1_year': 'ONLINE_AND_MAGAZINE_INCLUDE_TECH_FEST_1YEAR',
  'personal--online_and_magazine--include_tech_fest--2_year': 'ONLINE_AND_MAGAZINE_INCLUDE_TECH_FEST_2YEAR',
  // Techfest disabled
  'personal--online_only--monthly': 'ONLINE_ONLY_NO_TECH_FEST_MONTHLY',
  'personal--online_only--1_year': 'ONLINE_ONLY_NO_TECH_FEST_1YEAR',
  'personal--online_only--2_year': 'ONLINE_ONLY_NO_TECH_FEST_2YEAR',
  'personal--online_and_magazine--1_year': 'ONLINE_AND_MAGAZINE_NO_TECH_FEST_1YEAR',
  'personal--online_and_magazine--2_year': 'ONLINE_AND_MAGAZINE_NO_TECH_FEST_2YEAR',
  // Corporate abos
  'corporate--10--1_year': 'CORPORATE_ABO_TEAM_1YEAR',
  'corporate--10--2_year': 'CORPORATE_ABO_TEAM_2YEAR',
  'corporate--25--1_year': 'CORPORATE_ABO_BUSINESS_1YEAR',
  'corporate--25--2_year': 'CORPORATE_ABO_BUSINESS_2YEAR',
  'corporate--50--1_year': 'CORPORATE_ABO_ENTERPRISE_1YEAR',
  'corporate--50--2_year': 'CORPORATE_ABO_ENTERPRISE_2YEAR',
};

const corporateSignupRoute = '/membership/corporate';
const personalSignupRoute = '/membership/personal';

export default {
  components: {
    Choice,
    Ticket,
  },
  props: {
    label: {
      type: String,
    },
  },
  data() {
    return {
      decisionTree: decisionTree,
      currentPath: [],
    };
  },
  created() {
    this.currentPath.push({
      choice: this.decisionTree[0],
      selected: null,
    });
  },
  computed: {
    totalPrice() {
      return this.currentPath
          .filter(node => node.selected)
          .reduce((total, node) => total + (node.selected.price ? node.selected.price : 0), 0);
    },
    allSelected() {
      return this.currentPath.every(node => node.selected !== null);
    },
    subscriptionCode() {
      const selectedOptionsKey = this.currentPath
          .filter(node => node.selected)
          .map(node => node.selected.key)
          .join('--');

      return subscriptionCodes[selectedOptionsKey];
    },
    priceInfo() {
      const subscriptionCode = this.subscriptionCode;

      if (!subscriptionCode) {
        return null;
      }

      if (subscriptionCode.endsWith('_MONTHLY')) {
        return '*We incasseren per maand. Het abonnement is doorlopend en maandelijks opzegbaar.';
      }

      if (subscriptionCode.endsWith('_1YEAR')) {
        return '*We incasseren per jaar. Het abonnement is doorlopend.';
      }

      if (subscriptionCode.endsWith('_2YEAR')) {
        return '*We incasseren per twee jaar. Het abonnement is doorlopend.';
      }

      return null;
    }
  },
  methods: {
    handleChange(index, option) {
      this.currentPath[index].selected = option;

      // Remove all choices that come after the choice that was changed
      this.currentPath = this.currentPath.slice(0, index + 1);

      // Find the index of the current choice in the original decisionTree array
      const currentChoiceIndex = this.decisionTree.indexOf(this.currentPath[index].choice);

      // Find the next valid choice in the decision tree
      let nextChoiceIndex = currentChoiceIndex + 1;
      while (nextChoiceIndex < this.decisionTree.length) {
        const nextChoice = this.decisionTree[nextChoiceIndex];

        // If the next choice is valid according to the current conditions, add it to the path and break the loop
        if (this.validateConditions(nextChoice.conditions)) {
          this.currentPath.push({
            choice: nextChoice,
            selected: null,
          });

          break;
        }

        nextChoiceIndex++;
      }
    },
    validateConditions(conditions) {
      if (!conditions) {
        return true;
      }

      return Object.entries(conditions).every(([key, value]) => {
        const choice = this.currentPath.find((node) => node.choice.key === key);

        return choice && choice.selected && choice.selected.key === value;
      });
    },
    nextButtonHref() {
      let url = personalSignupRoute;

      if (typeof corporateSignupRoute !== 'undefined') {
        url = this.validateConditions({'subscriber-type': 'corporate'}) ? corporateSignupRoute : personalSignupRoute;
      }

      return url + '?subscription=' + this.subscriptionCode;
    },
  },
};
</script>
