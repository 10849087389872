"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNumberOfMagazines = exports.initNumberOfMagazines = void 0;
function initNumberOfMagazines($) {
    window.pubSub.subscribe('numberOfMagazinesChanged', function (event) {
        var numberOfMagazines = event.numberOfMagazines;
        if (!numberOfMagazines)
            return;
        var selectedOption = $('#membership_form_numberOfMagazines option:selected');
        var price = selectedOption.data('price');
        if (typeof price === 'undefined') {
            return;
        }
        // Ticket info
        $('.membership-price').text(price);
        $('#subscription-summary-price').text(price).parent().removeClass('u-hidden');
        var discountedPrice = selectedOption.data('discounted-price');
        if (typeof discountedPrice !== 'undefined') {
            $('.membership-price-discounted').text(discountedPrice);
            $('.price-row-discounted').removeClass('u-hidden');
        }
        else {
            $('.price-row').removeClass('u-hidden');
        }
        // USPs
        $('#number-of-magazines').text(numberOfMagazines);
        $('#number-of-magazines-plural').toggle(numberOfMagazines !== 1);
        $('.c-ticket__subscription_include').removeClass('u-hidden');
        $('[data-show-when-number-of-magazines-gte]').each(function (_, elem) {
            var show = numberOfMagazines < parseInt($(elem).data('show-when-number-of-magazines-gte'), 10);
            $(elem).toggleClass('u-hidden', show);
        });
        $('[data-show-when-number-of-magazines-lte]').each(function (_, elem) {
            var show = numberOfMagazines > parseInt($(elem).data('show-when-number-of-magazines-lte'), 10);
            $(elem).toggleClass('u-hidden', show);
        });
    });
}
exports.initNumberOfMagazines = initNumberOfMagazines;
function getNumberOfMagazines($) {
    var selectedOption = $('#membership_form_numberOfMagazines option:selected');
    var numberOfMagazines = selectedOption.data('number-of-magazines');
    return numberOfMagazines;
}
exports.getNumberOfMagazines = getNumberOfMagazines;
