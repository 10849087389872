"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.voucherService = void 0;
exports.voucherService = {
    checkVoucher: function (voucher) {
        return new Promise(function (resolve, reject) {
            $.ajax("/check-voucher?code=".concat(voucher), {
                method: 'GET',
            })
                .done(function () {
                resolve(true);
            })
                .fail(function (response) {
                if (response.status === 404) {
                    resolve(false);
                }
                reject();
            });
        });
    }
};
