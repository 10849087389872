"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SubscriptionOptionChanged = /** @class */ (function () {
    function SubscriptionOptionChanged(name, option) {
        this.name = name;
        this.option = option;
    }
    return SubscriptionOptionChanged;
}());
exports.default = SubscriptionOptionChanged;
