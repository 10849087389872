"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initVoucherCheck = void 0;
var voucherService_1 = require("./services/voucherService");
var VoucherRedeemed_1 = require("./events/VoucherRedeemed");
function initVoucherCheck($) {
    var voucherCheckButton = $('.js-redeem-voucher');
    voucherCheckButton.each(function () {
        var _this = this;
        this.addEventListener('click', function () {
            var voucherInput = $('#membership_form_discountVoucher');
            var voucher = voucherInput.val();
            if (voucher.length === 0) {
                $('#voucher-invalid').removeClass('u-hidden');
                return;
            }
            $('#voucher-invalid').addClass('u-hidden');
            $('#voucher-error').addClass('u-hidden');
            voucherService_1.voucherService.checkVoucher(voucher)
                .then(function (isValid) {
                if (isValid) {
                    $('#membership_form_discountVoucher').attr('readonly', 'readonly').addClass('parsley-success');
                    $(_this).prop('disabled', true);
                    window.pubSub.publish('voucherRedeemed', new VoucherRedeemed_1.VoucherRedeemed());
                    return;
                }
                $('#voucher-invalid').removeClass('u-hidden');
            })
                .catch(function () {
                $('#voucher-error').removeClass('u-hidden');
            });
        });
    });
}
exports.initVoucherCheck = initVoucherCheck;
