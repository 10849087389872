"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1() {
    var elements = document.querySelectorAll('[data-fp]');
    if (elements.length > 0) {
        if (typeof window.getFingerprint3 === "function") {
            var fingerprint = window.getFingerprint3();
            for (var _i = 0, _a = elements; _i < _a.length; _i++) { // eslint-disable-line @typescript-eslint/no-explicit-any
                var element = _a[_i];
                element.value = fingerprint;
            }
        }
    }
}
exports.default = default_1;
