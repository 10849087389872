<template>
  <div class="c-ticket" :class="{ 'c-ticket--expanded': expanded, 'c-ticket--visible': this.price}">
    <div class="c-ticket__overlay" @click="contractTicket"></div>
    <div class="c-ticket__ticket" @click="toggleTicket">
      <div class="c-ticket__summary">
        <span class="c-ticket__summary_choice">Jouw abonnement</span>
        <div class="c-ticket__summary_subscription">
          <div class="c-ticket__summary_subscription__price">
            <span id="subscription-summary-price">${ formatPrice(price) }</span> p.m.
          </div>
        </div>
      </div>
      <div class="c-ticket__body">
        <h2 class="c-signup-title">Jouw abonnement</h2>
        <div class="c-pill c-pill--pro c-signup-price">per maand <span class="c-signup-price--price">${ formatPrice(price) }</span> excl. BTW<span v-if="priceInfo">*</span>
        </div>
        <div class="c-signup-price-info" v-if="priceInfo">${ priceInfo }</div>
      </div>
      <div class="c-ticket__details">
        <div class="c-ticket__body">
          <ul class="c-ticket__subscription_includes">
            <li class="c-ticket__subscription_include">Onbeperkt PRO artikelen</li>
            <li class="c-ticket__subscription_include">Incl. CTO interviews, podcasts en digitale specials</li>
            <li class="c-ticket__subscription_include">Dagelijkse nieuwsbrief</li>
          </ul>
        </div>
        <hr v-if="this.currentPath">
        <div class="c-ticket__body">
          <ul class="c-ticket__subscription_includes--dynamic">
            <li class="c-ticket__subscription_include" v-for="(node) in this.currentPath">
              <span class="c-ticket__subscription_include__label">${ node.selected.label }</span>
              <span class="c-ticket__subscription_include__price" v-if="node.selected.price">${ formatPrice(node.selected.price) }</span>
              <span class="c-ticket__subscription_include__before-discount-price"
                    v-if="node.selected.beforeDiscountPrice">${ formatPrice(node.selected.beforeDiscountPrice) }</span>
              <span class="c-ticket__subscription_include__description" v-if="node.selected.description">${ node.selected.description }</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const breakpoint = 1024;

export default {
  props: {
    price: {
      type: [String, Number],
    },
    currentPath: {
      type: [Object],
    },
    priceInfo: {
      type: String,
    }
  },
  data() {
    return {
      expanded: false,
    };
  },
  methods: {
    toggleTicket() {
      const windowWidth = $(window).width();
      if (windowWidth && windowWidth >= breakpoint) return;

      this.expanded = !this.expanded;
    },
    contractTicket() {
      const windowWidth = $(window).width();
      if (windowWidth && windowWidth >= breakpoint) return;

      this.expanded = false;
    },
  },
};
</script>
