"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initToggleButtonGroup = void 0;
function initToggleButtonGroup($) {
    var shouldRun = $('.u-toggle-button-group').length > 0;
    if (!shouldRun) {
        return;
    }
    var buttonGroup = $('.c-button-group');
    var buttonGroupIcon = $('.c-button-group__icon');
    var heightClosed = buttonGroup[0].offsetHeight;
    var heightOpen = 0;
    $(window).on('resize', function () {
        if (buttonGroupIcon.is(':hidden')) {
            buttonGroup.css({ height: 'auto' });
            return;
        }
        heightOpen = buttonGroup.height('auto')[0].offsetHeight;
        var endHeight = buttonGroup.hasClass('is-open') ? 'auto' : heightClosed;
        buttonGroup.css({ height: endHeight });
    }).trigger('resize');
    $('.u-toggle-button-group').on('click', function () {
        buttonGroup.toggleClass('is-open');
        buttonGroupIcon.toggleClass('is-open');
        var endHeight = buttonGroup.hasClass('is-open') ? heightOpen : heightClosed;
        buttonGroup.animate({
            height: endHeight
        }, 300);
    });
}
exports.initToggleButtonGroup = initToggleButtonGroup;
