"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PubSub = /** @class */ (function () {
    function PubSub() {
        this.subscribers = {}; // eslint-disable-line @typescript-eslint/no-explicit-any
    }
    PubSub.prototype.publish = function (eventName, event) {
        if (!this.subscribers[eventName]) {
            return;
        }
        this.subscribers[eventName].forEach(function (callback) {
            callback(event);
        });
    };
    PubSub.prototype.subscribe = function (eventName, callback) {
        if (!this.subscribers[eventName]) {
            this.subscribers[eventName] = [];
        }
        this.subscribers[eventName].push(callback);
    };
    return PubSub;
}());
exports.default = PubSub;
