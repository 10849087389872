"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var StepCompleted = /** @class */ (function () {
    function StepCompleted(currentStep, nextStep) {
        this.currentStep = currentStep;
        this.nextStep = nextStep;
    }
    return StepCompleted;
}());
exports.default = StepCompleted;
