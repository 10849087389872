"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.trackEvent = void 0;
function trackEvent(category, action, label) {
    if (typeof window.ga !== 'function') {
        return;
    }
    window.ga('gtm1.send', 'event', category, action, label);
}
exports.trackEvent = trackEvent;
