"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1($) {
    var emailAddress = $('#add_username_password_token_emailAddress');
    if (emailAddress.length < 1) {
        return;
    }
    var currentEmailAddress = emailAddress.data('current-email-address');
    $('.form__warning--email-change').hide();
    emailAddress.on('change input', function (event) {
        var show = emailAddress.val() !== currentEmailAddress;
        if (show) {
            $(event.target).
                closest('form').
                find('.form__warning--email-change').
                slideDown();
        }
        else {
            $(event.target).
                closest('form').
                find('.form__warning--email-change').
                slideUp();
        }
    });
}
exports.default = default_1;
