"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1($) {
    $('form').submit(function () {
        $('button[type="submit"]:not(:disabled),input[type="submit"]:not(:disabled)').prop('disabled', true).addClass("pre-form-submit-disabled");
    });
    window.Parsley.on('form:error', function () {
        $('.pre-form-submit-disabled').prop('disabled', false).removeClass("pre-form-submit-disabled");
    });
}
exports.default = default_1;
