"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1($) {
    var buttons = $('.u-auto-confirm');
    if (!buttons.length) {
        return;
    }
    buttons.eq(0).trigger('click').prop('disabled', true);
}
exports.default = default_1;
