"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateOrderTicket = exports.initOrderTicket = void 0;
function initOrderTicket($) {
    $('.c-ticket__summary').on('click', function () {
        $('.c-ticket').toggleClass('c-ticket--expanded');
    });
}
exports.initOrderTicket = initOrderTicket;
function updateOrderTicket($, reportSubject, reportLocation, reportType, reportPrice) {
    if (reportSubject === void 0) { reportSubject = null; }
    if (reportLocation === void 0) { reportLocation = null; }
    if (reportType === void 0) { reportType = null; }
    if (reportPrice === void 0) { reportPrice = null; }
    var item = $('.is-selected').closest('[data-name]');
    if (item.length === 0) {
        return;
    }
    $('.c-ticket').addClass('c-ticket--visible').find('.u-hidden').removeClass('.u-hidden');
    $('.c-ticket').find('.u-hidden').removeClass('u-hidden');
    if (reportSubject !== null) {
        var subject = $('#report-subject');
        subject.parent().removeClass('u-hidden');
        subject.text(reportSubject);
    }
    if (reportLocation !== null) {
        var location_1 = $('#report-location');
        location_1.parent().removeClass('u-hidden');
        location_1.text(reportLocation);
    }
    if (reportType !== null) {
        $('#report-type').text(item.data('name'));
    }
    if (reportPrice !== null) {
        var price = $('#report-price');
        if (reportType === 'custom') {
            reportPrice = '-';
        }
        price.text(reportPrice);
    }
}
exports.updateOrderTicket = updateOrderTicket;
