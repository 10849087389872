"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.debounce = exports.formatPrice = exports.nothing = void 0;
function nothing(event) {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
    return false;
}
exports.nothing = nothing;
function formatPrice(price) {
    if (typeof price === 'string') {
        price = parseFloat(price);
    }
    if (typeof price !== 'number') {
        return ' ??? ';
    }
    return '€ ' + price.toFixed(2).replace('.', ',');
}
exports.formatPrice = formatPrice;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function debounce(fn, delay) {
    var timeoutId;
    return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        clearTimeout(timeoutId);
        timeoutId = window.setTimeout(function () {
            fn.apply(void 0, args);
        }, delay);
    };
}
exports.debounce = debounce;
