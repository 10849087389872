"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MembershipDetails = void 0;
var MembershipDetails = /** @class */ (function () {
    function MembershipDetails(name, priceSuffix, subscriptionDuration, pricePerMonth, pricePerYear, idealAmount, fullPricePerYear, useDeliveryDetailsForInvoicing, displayNumberOfSeats) {
        this.name = name;
        this.priceSuffix = priceSuffix;
        this.subscriptionDuration = subscriptionDuration;
        this.pricePerMonth = pricePerMonth;
        this.pricePerYear = pricePerYear;
        this.idealAmount = idealAmount;
        this.fullPricePerYear = fullPricePerYear;
        this.useDeliveryDetailsForInvoicing = useDeliveryDetailsForInvoicing;
        this.displayNumberOfSeats = displayNumberOfSeats;
    }
    return MembershipDetails;
}());
exports.MembershipDetails = MembershipDetails;
