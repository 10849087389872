"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    methods: {
        formatPrice: function (cents) {
            var euros = cents / 100;
            var hasDecimal = euros % 1 !== 0;
            var formattedPrice = hasDecimal ? euros.toFixed(2).replace('.', ',') : euros + ",-";
            return "\u20AC".concat(formattedPrice);
        },
    },
};
