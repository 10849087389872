"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initLandingPages = void 0;
var agLanding_1 = require("./agLanding");
var adfoLanding_1 = require("./adfoLanding");
var bbLanding_1 = require("./bbLanding");
var membershipBuilder_1 = require("./membershipBuilder");
function initLandingPages($) {
    (0, agLanding_1.initAgLanding)($);
    (0, adfoLanding_1.initAdfoLanding)($);
    (0, bbLanding_1.initBbLanding)($);
    (0, membershipBuilder_1.initMembershipBuilder)($);
}
exports.initLandingPages = initLandingPages;
