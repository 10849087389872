"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1($) {
    var sampleAddress = $('#sample-address');
    if (!sampleAddress.length) {
        return;
    }
    $('#basic_registration_sendSample').on('change', function (event) {
        var target = event.target;
        if (target.checked) {
            sampleAddress.show();
            $('input', sampleAddress).prop('required', true);
            return;
        }
        sampleAddress.hide();
        $('input', sampleAddress).prop('required', false);
    }).change();
}
exports.default = default_1;
