"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1($) {
    var theme = document.body.getAttribute('data-theme');
    if (theme !== 'mena') {
        return;
    }
    var birthDateField = document.getElementById('corporate-abo-birthdate');
    var subscriptionTypeNode = document.getElementById('corporate_abo_user_data_licenceType');
    if (birthDateField instanceof Node && subscriptionTypeNode instanceof Node) {
        birthDateField.hidden = true;
        var licenceToggle = $('input:radio[name="corporate_abo_user_data[licenceType][licence]"]');
        licenceToggle.each(function () {
            if ($(this).val() === 'CORPORATE_ABO_YOUNG_LICENCEE' && $(this).is(':checked')) {
                birthDateField.hidden = false;
                $(birthDateField).find('.c-form-label').toggleClass('required', true);
                $(birthDateField).find('select').last().prop('required', true);
            }
        });
        licenceToggle.on('change', function () {
            var required = $(this).val() === 'CORPORATE_ABO_YOUNG_LICENCEE';
            birthDateField.hidden = !required;
            $(birthDateField).find('.c-form-label').toggleClass('required', required);
            $(birthDateField).find('select').last().prop('required', required);
        });
    }
}
exports.default = default_1;
