"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1($) {
    /*
    *  Membership type changed
    */
    var THEME = $('body').data('theme');
    window.pubSub.subscribe('membershipTypeChanged', function (event) {
        var membershipType = event.membershipType;
        $('#membership_form_membershipType').val(membershipType).trigger('change');
        $('.c-membership-type.is-selected').removeClass('is-selected');
        $('.c-pricing-option.is-selected').removeClass('is-selected');
        $("[data-type=\"".concat(membershipType, "\"]")).children('.c-pricing-option').addClass('is-selected');
        $("[data-type=\"".concat(membershipType, "\"]")).children('.c-membership-type').addClass('is-selected');
    });
    window.pubSub.subscribe('numberOfSeatsChanged', function (event) {
        var numberOfSeats = event.numberOfSeats;
        if (!numberOfSeats)
            return;
        $('#corporate_abo_request_numberOfSeats').val(numberOfSeats).trigger('change');
        $('#corporate_abo_request_privacy_statement').removeClass('u-hidden').show();
    });
    // BB specific - toggle Personal address fields
    if (THEME === 'binnenlandsbestuur') {
        window.pubSub.subscribe('membershipTypeChanged', function (event) {
            var privateAddress = $('#private_address_wrapper');
            var isDigitalOnly = event.membershipType === '1YEAR_ONLINE_ONLY';
            if (isDigitalOnly) {
                privateAddress.find(':input[value="private"]').prop('checked', false);
                privateAddress.find(':input[value="business"]').prop('checked', true);
                privateAddress.find(':input').prop('required', false);
                privateAddress.find('label').toggleClass('required', false);
            }
            else {
                privateAddress.find(':input[value="business"]').prop('checked', false);
                privateAddress.find(':input[value="private"]').prop('checked', true);
            }
            privateAddress.toggle(!isDigitalOnly);
            privateAddress.find(':input').not('[id$="streetName"], [id$="city"]').prop('readonly', isDigitalOnly);
        });
    }
    if (THEME === 'binnenlandsbestuur') {
        window.pubSub.subscribe('addressTypeChanged', function (event) {
            var type = event.addressType;
            var privateAddress = $('#private_address_wrapper');
            var businessAddress = $('#business_address_wrapper');
            var isPrivate = event.addressType === 'private';
            privateAddress.find(':input').prop('required', type === 'private');
            privateAddress.find('label').toggleClass('required', type === 'private');
            businessAddress.find(':input').prop('required', type === 'business');
            businessAddress.find('label').toggleClass('required', type === 'business');
            privateAddress.toggle(isPrivate);
            businessAddress.toggle(!isPrivate);
        });
    }
    // Toggle subscription end date for raadslid or lid Provinciale Staten
    if (THEME === 'binnenlandsbestuur') {
        window.pubSub.subscribe('jobTitleSelected', function (event) {
            var raadslid = '97bd2f01-6481-4a8c-98d8-74cb3fb733d5';
            var lidProvincialeStaten = '7b0b04f1-4463-42ef-ac6a-26d29b632994';
            var requiresEndDate = (event.jobTitle === raadslid || event.jobTitle === lidProvincialeStaten);
            $('#counsilor-enddate').toggle(requiresEndDate);
            $('#counsilor-enddate select').prop('required', requiresEndDate);
        });
    }
    // Toggle business name / organization size / vat number
    if (THEME === 'adformatie' || THEME === 'agconnect' || THEME === 'dealdata' || THEME === 'dealmakerdata' || THEME === 'agconnect-new') {
        window.pubSub.subscribe('addressTypeChanged', function (event) {
            $('#c-company-details').toggle(event.addressType === 'business');
            $('#c-company-details input').prop('required', event.addressType === 'business');
            $('#c-company-details select').prop('required', event.addressType === 'business');
            $('.c-vat-number').toggle(event.addressType === 'business');
        });
    }
    if (THEME === 'mena' || THEME === 'cfo') {
        window.pubSub.subscribe('addressTypeChanged', function (event) {
            $('#c-company-details input').prop('required', event.addressType === 'business');
            $('#c-company-details input').parent().parent().find('label').toggleClass('required', event.addressType === 'business');
            $('#c-company-details select').prop('required', event.addressType === 'business');
            $('#c-company-details select').parent().parent().find('label').toggleClass('required', event.addressType === 'business');
        });
    }
    // Toggle paying by invoice
    if (THEME === 'adformatie' || THEME === 'agconnect') {
        window.pubSub.subscribe('membershipTypeChanged', function (event) {
            var isWithoutInvoice = event.membershipType === '1MONTH';
            $('[name="membership_form[paymentMethod][paymentMethod]"][value="invoice"]').closest('.c-radio-button').toggle(!isWithoutInvoice);
            var paymentMethods = $('#membership_form_paymentMethod_paymentMethod');
            var availablePaymentMethods = paymentMethods.children('span[style!="display: none;"]');
            if (availablePaymentMethods.length === 1) {
                paymentMethods.hide();
                availablePaymentMethods.children('input').prop('checked', true);
                var isCollection = availablePaymentMethods.children('input').val() === 'collection';
                $('#payment-method-collection-only-warning, #iban-field').removeClass('u-hidden').toggle(isCollection);
            }
            else {
                paymentMethods.show();
                availablePaymentMethods.children('input').prop('checked', false);
                $('#iban-field').hide();
                $('#payment-method-collection-only-warning').hide();
            }
        });
    }
    // Hide other membership types. Behind an if until every brand is prepared
    if (THEME === 'binnenlandsbestuur' || THEME === 'agconnect' || THEME === 'ibestuur') {
        window.pubSub.subscribe('membershipTypeChanged', function (event) {
            var type = event.membershipType;
            if (type) {
                $('.c-membership-type-block__content').addClass('is-animating');
                $('.c-membership-type-block__item').not($("[data-type=\"".concat(type, "\"]")))
                    .animate({ height: 0, opacity: 0 }, 200, function () {
                    $(this).hide();
                    $('.c-membership-type-block__content').removeClass('is-animating');
                });
                $('#membership-type-change-button').removeClass('u-hidden').show();
            }
        });
    }
    // Hide other membership types. Behind an if until every brand is prepared
    if (THEME === 'adformatie') {
        window.pubSub.subscribe('membershipTypeChanged', function (event) {
            var type = event.membershipType;
            if (type) {
                $('.c-membership-type-block__content').addClass('is-animating');
                $('.c-membership-type-block__item').not($("[data-type=\"".concat(type, "\"]")))
                    .animate({ height: 0, opacity: 0 }, 200, function () {
                    $(this).hide();
                    $('.c-membership-type-block__content').removeClass('is-animating');
                });
            }
        });
    }
    /*
    *  Premium type changed
    */
    window.pubSub.subscribe('premiumTypeChanged', function (event) {
        var premiumType = event.premiumType;
        $('#membership_form_premiumType').val(premiumType).trigger('change');
        $('.c-premium-type.is-selected').removeClass('is-selected');
        $("[data-type=\"".concat(premiumType, "\"]")).children('.c-premium-type').addClass('is-selected');
    });
    /*
    *  Recipient changed
    */
    window.pubSub.subscribe('recipientChanged', function (event) {
        var recipient = event.recipient;
        $('input, select', $('.recipient')).prop('required', false);
        $('.recipient').hide();
        var details = $("#recipient-".concat(recipient, "-details"));
        details.show();
        $('input, select', details).prop('required', true);
        if (recipient === "minor") {
            $('#membership_form_minor_lastNamePreposition').prop('required', false);
            $('#membership_form_lessonSheet_0, #membership_form_lessonSheet_1').prop("checked", false);
            $('#subscription-price-per-year-lesson-sheet').hide();
            $('#subscription-price-per-year').show();
            $('#subscription-summary-price-per-year-lesson-sheet').hide();
            $('#subscription-summary-price').show();
            $('[class*=c-ticket__subscription_includes][class*=u-hidden]').hide();
        }
    });
}
exports.default = default_1;
