"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PostalCodeEntered = /** @class */ (function () {
    function PostalCodeEntered(postalCode, houseNumber, addressType, formPrefix, countryCode) {
        this.postalCode = postalCode;
        this.houseNumber = houseNumber;
        this.addressType = addressType;
        this.formPrefix = formPrefix;
        this.countryCode = countryCode;
    }
    return PostalCodeEntered;
}());
exports.default = PostalCodeEntered;
