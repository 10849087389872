<template>
  <div>
    <div class="t-heading">
      <h2>${ choice.label }</h2>
    </div>
    <div
        v-for="(option, index) in choice.options"
        :key="index"
        class="c-button c-button--regular c-button--primary c-membership-builder--option"
        :class="{ 'c-button--secondary': selected !== option, 'c-button--confirm c-membership-builder--option--selected': selected === option  }"
        @click="selectOption(option)"
    >
      <span class="c-button-text c-membership-builder--option--label">${ option.label }</span>
      <div v-if="option.price" class="c-membership-builder--option--price">+ ${ formatPrice(option.price) }</div>
      <div v-if="option.description" class="c-membership-builder--option--description">${ option.description }</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    choice: {
      type: Object,
      required: true,
    },
    selected: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    selectOption(option) {
      if (!this.disabled && this.selected !== option) {
        this.$emit('change', option);
      }
    },
  },
};
</script>
