"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initOrderSteps = void 0;
function initOrderSteps($) {
    var steps = getAllOrderSteps($, true);
    if (steps.length <= 1) {
        return;
    }
    $('#terms-conditions').hide();
    for (var i = 1; i < steps.length; i++) {
        $('#' + steps[i]).hide();
    }
    if ($('.c-pricing-option__cta, .c-membership-type__cta').length > 0) {
        $('.c-pricing-option__cta, .c-membership-type__cta').on('click', function () {
            advanceToOrderStep($, steps[1]);
        });
    }
    else {
        initializeOrderStep($, steps[0]);
    }
    $('.c-step-indicator__step a:not(.is-completed)').first().addClass('is-active');
    $('.c-step-indicator__step a:not(.is-completed):not(.is-active)').addClass('is-uncompleted');
}
exports.initOrderSteps = initOrderSteps;
function advanceToOrderStep($, stepName) {
    var currentStep = $('#' + stepName);
    var currentStepWasAlreadyVisible = currentStep.is(':visible');
    if (currentStepWasAlreadyVisible) {
        return;
    }
    var steps = getAllOrderSteps($, false);
    currentStep.show();
    scrollTo($, currentStep);
    $('.c-step.is-active').removeClass('is-active').addClass('is-completed');
    $('#step-indicator-' + stepName).removeClass('is-uncompleted').addClass('is-active');
    if (steps.length === 2) {
        $('#terms-conditions').show();
    }
    initializeOrderStep($, stepName);
}
function initializeOrderStep($, stepName) {
    var steps = getAllOrderSteps($, false);
    var doneButton = $('#' + stepName + '-done');
    var fields = $('#' + stepName + ' :input:not(:button)');
    fields.each(function (i, elem) {
        if ($(elem).attr('data-parsley-group')) {
            if ($(elem).attr('data-parsley-group') !== stepName) {
                $(elem).attr('data-parsley-group', JSON.stringify([$(elem).attr('data-parsley-group'), stepName]));
            }
        }
        else {
            $(elem).attr('data-parsley-group', stepName);
        }
    });
    doneButton.on('click', function () {
        $('#atlasOrderForm').parsley().whenValidate({ group: stepName }).done(function () {
            doneButton.hide();
            advanceToOrderStep($, steps[1]);
        });
    });
    if (steps.length > 1) {
        doneButton.closest('.c-form-footer__action').removeClass('u-hidden');
    }
}
function getAllOrderSteps($, includeHidden) {
    var stepElements = $('.c-step-indicator__step .c-step:not(.is-completed)' + (includeHidden ? '' : ':not(.u-hidden)'));
    return stepElements.map(function (_, element) {
        return $(element).data('section');
    }).get();
}
function scrollTo($, element) {
    var offset = element.offset();
    if (!offset) {
        return;
    }
    $('html, body').animate({
        scrollTop: offset.top - 100
    }, 1000);
}
