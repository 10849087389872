"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1($) {
    var $deliveryAddress = $('#delivery-address');
    $('[id$="useSeparateDeliveryAddress"]').on('change', function (e) {
        var target = e.target;
        var isChecked = target.value === '1';
        if (isChecked) {
            $deliveryAddress.show();
        }
        else {
            $deliveryAddress.hide();
        }
        $('input', $deliveryAddress).prop('required', isChecked);
    }).change();
}
exports.default = default_1;
