"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1($) {
    var $invoiceAddress = $('#invoice-address');
    $('[id$="useSeparateInvoiceAddress"]').on('change', function (e) {
        var target = e.target;
        var isChecked = target.checked;
        if (isChecked) {
            $invoiceAddress.show();
        }
        else {
            $invoiceAddress.hide();
        }
        $('input', $invoiceAddress).prop('required', isChecked);
    }).change();
}
exports.default = default_1;
