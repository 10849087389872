"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PremiumTypeChanged = /** @class */ (function () {
    function PremiumTypeChanged(premiumType, premiumName, usps) {
        this.premiumType = premiumType;
        this.premiumName = premiumName;
        this.usps = usps;
    }
    return PremiumTypeChanged;
}());
exports.default = PremiumTypeChanged;
