"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ga_1 = require("./ga");
function initFormTracking($) {
    $('form[data-tracking-name]').each(function () {
        var trackingName = $(this).data('tracking-name');
        $(this).find('button').on('click', function () {
            var dataLabel = $(this).data('tracking-label');
            var label = dataLabel || $(this).text();
            (0, ga_1.trackEvent)('interaction', 'Formulier: ' + trackingName, label.trim());
        });
    });
}
exports.default = initFormTracking;
