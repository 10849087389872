"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initSignupTicket = void 0;
var ticket_1 = require("../ticket");
function initSignupTicket($) {
    initSubscribers($);
    (0, ticket_1.initTicket)($);
}
exports.initSignupTicket = initSignupTicket;
function initSubscribers($) {
    /*
    *  Ticket info
    */
    var THEME = $('body').data('theme');
    window.pubSub.subscribe('membershipTypeChanged', function (event) {
        if (!event.membershipType)
            return;
        $('.c-ticket').addClass('c-ticket--visible').find('.u-hidden').removeClass('.u-hidden');
        if (THEME !== 'agconnect') {
            $('.c-ticket').find('.u-hidden').removeClass('u-hidden');
        }
        var membershipDetails = event.membershipDetails;
        $('#subscription-name, #subscription-summary-name').text(membershipDetails.name);
        $('#subscription-seats').text(membershipDetails.displayNumberOfSeats);
        $('#subscription-price-suffix').text(membershipDetails.priceSuffix);
        $('#subscription-duration').text(membershipDetails.subscriptionDuration);
        $('#subscription-price-per-month').text(membershipDetails.pricePerMonth);
        $('#subscription-price-per-year, #subscription-price-first-period').text(membershipDetails.pricePerYear);
        $('#subscription-ideal-amount').text(membershipDetails.idealAmount);
        $('#subscription-full-price-per-year').text(membershipDetails.fullPricePerYear);
        var summaryPrice = membershipDetails.pricePerMonth || membershipDetails.pricePerYear;
        $('#subscription-summary-price').text(summaryPrice);
        if (membershipDetails.fullPricePerYear) {
            var label = 'Eerste jaar:';
            if (membershipDetails.subscriptionDuration === '2 jaar') {
                label = 'Eerste twee jaar:';
            }
            $('#subscription-price-first-period-label').text(label);
            $('.c-ticket__flexible-price').show();
            $('.c-ticket__fixed-price').hide();
        }
        else {
            $('.c-ticket__fixed-price').show();
            $('.c-ticket__flexible-price').hide();
        }
    });
    if (THEME === 'dealmakerdata') {
        window.pubSub.subscribe('voucherRedeemed', function () {
            $('#subscription-price-per-year').html('&euro; 0,00');
            $('#subscription-ideal-amount').html('&euro; 0,00');
        });
    }
    if (THEME === 'agconnect' || THEME === 'adformatie') {
        window.pubSub.subscribe('membershipTypeChanged', function (event) {
            var membershipType = event.membershipType;
            if (membershipType.substr(0, 5) === '2YEAR') {
                $('.u-only-compleet-2').removeClass('u-hidden').show();
                $('#subscription-price-first-period-label').text('Eerste jaar:');
            }
            else {
                $('.u-only-compleet-2').hide();
            }
            if (membershipType === '1MONTH') {
                $('.u-only-online-only').removeClass('u-hidden').show();
                $('.u-only-compleet').hide();
            }
            else {
                $('.u-only-compleet').removeClass('u-hidden').show();
                $('.u-only-online-only').hide();
            }
        });
    }
    window.pubSub.subscribe('premiumTypeChanged', function (event) {
        $('#subscription-premium').text(event.premiumName);
    });
    window.pubSub.subscribe('stepUncompleted', function (event) {
        if (event.stepId === 'premium') {
            $('#subscription-premium').text('-');
        }
    });
    window.pubSub.subscribe('lessonSheetChanged', function (event) {
        if (!event.lessonSheet) {
            $('#subscription-price-per-year-lesson-sheet').hide();
            $('#subscription-full-price-per-year-lesson-sheet').hide();
            $('#subscription-price-per-year').show();
            $('#subscription-full-price-per-year').show();
            $('#subscription-summary-price-per-year-lesson-sheet').hide();
            $('#subscription-summary-price').show();
        }
        else if (event.lessonSheet) {
            $('#subscription-price-per-year-lesson-sheet').removeClass('u-hidden').show();
            $('#subscription-full-price-per-year-lesson-sheet').removeClass('u-hidden').show();
            $('#subscription-price-per-year').hide();
            $('#subscription-full-price-per-year').hide();
            $('#subscription-summary-price').hide();
            $('#subscription-summary-price-per-year-lesson-sheet.u-hidden').removeClass('u-hidden');
            $('#subscription-summary-price-per-year-lesson-sheet').show();
        }
    });
    /*
    *  USPs
    */
    window.pubSub.subscribe('membershipTypeChanged', function (event) {
        var uspsParent = $('.c-ticket__subscription_includes').first().parent();
        var dynamicUSPs = $('.c-ticket__subscription_includes--dynamic');
        if (!dynamicUSPs.length) {
            dynamicUSPs = $(document.createElement('ul')).addClass('c-ticket__subscription_includes--dynamic');
            uspsParent.append(dynamicUSPs);
        }
        dynamicUSPs.empty();
        event.usps.filter(function (v) { return v !== ''; }).forEach(function (usp) {
            var li = $(document.createElement('li')).addClass('c-ticket__subscription_include').text(usp);
            dynamicUSPs.append(li);
        });
    });
    window.pubSub.subscribe('premiumTypeChanged', function (event) {
        var uspsParent = $('.c-ticket__subscription_includes').first().parent();
        var premiumUSPs = $('.c-ticket__subscription_includes--premium');
        if (!premiumUSPs.length) {
            uspsParent.append($(document.createElement('ul')).addClass('c-ticket__subscription_includes--premium'));
            premiumUSPs = $('.c-ticket__subscription_includes--premium');
        }
        premiumUSPs.empty();
        event.usps.filter(function (v) { return v !== ''; }).forEach(function (usp) {
            var li = $(document.createElement('li')).addClass('c-ticket__subscription_include').text(usp);
            premiumUSPs.append(li);
        });
    });
    window.pubSub.subscribe('lessonSheetChanged', function (event) {
        if (!event.lessonSheet) {
            $('.c-ticket__subscription_includes--lesson-sheet').hide();
        }
        else if (event.lessonSheet) {
            $('.c-ticket__subscription_includes--lesson-sheet').removeClass('u-hidden').show();
        }
    });
}
