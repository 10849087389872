"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var MembershipTypeChanged = /** @class */ (function () {
    function MembershipTypeChanged(membershipType, membershipDetails, usps) {
        this.membershipType = membershipType;
        this.membershipDetails = membershipDetails;
        this.usps = usps;
    }
    return MembershipTypeChanged;
}());
exports.default = MembershipTypeChanged;
