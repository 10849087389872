"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initTicket = void 0;
function initTicket($) {
    var breakpoint = 1024;
    $('.c-ticket__ticket').on('click', function () {
        var windowWidth = $(window).width();
        if (windowWidth && windowWidth >= breakpoint)
            return;
        $('.c-ticket').toggleClass('c-ticket--expanded');
    });
    $('.c-ticket__overlay, .c-step-indicator').on('click', function () {
        var windowWidth = $(window).width();
        if (windowWidth && windowWidth >= breakpoint)
            return;
        $('.c-ticket').removeClass('c-ticket--expanded');
    });
}
exports.initTicket = initTicket;
