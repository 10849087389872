"use strict";
/*
JavaScript functions to make hiding and showing of HTML elements easier from within the PHP.
A click event will be set on the element containing the correct data attribute.

Valid data attributes (one is required):
    data-toggle
    data-show
    data-hide

The value of the attribute should be a jQuery selector that will point to the target(s) to be shown/hidden

Possible data attributes
    To hide the start element add:
        data-hide-self="true"
    Make sure element or it's parents won't get hidden:
        data-hide-self="false"
    Toggle text:
        data-replace-text-content="text to replace with"
        data-replace-text-target="selector" (optional, the element is be the text target by default)

*/
Object.defineProperty(exports, "__esModule", { value: true });
function default_1($) {
    function handleClick($e) {
        var $source = $($e.target);
        if ($source.data('hide')) {
            var $target = $($source.data('hide'));
            if (!$source.data('hide-self')) {
                $target = $target.filter(function () {
                    var targetDOMelement = $(this).get(0);
                    return !$.contains(targetDOMelement, $source[0]);
                });
            }
            $target.hide();
        }
        if ($source.data('show')) {
            $($source.data('show')).removeClass('u-hidden').show();
        }
        if ($source.data('toggle')) {
            $($source.data('toggle')).toggle();
        }
        if ($source.data('hide-self')) {
            $source.hide();
        }
        if ($source.data('replace-text-content')) {
            var textTarget = $source.data('replace-text-target') ? $($source.data('replace-text-target')) : $source;
            var oldText = $(textTarget).text();
            $(textTarget).text($source.data('replace-text-content'));
            $source.data('replace-text-content', oldText);
        }
    }
    $('[data-toggle], [data-show], [data-hide]').each(function (i, e) {
        $(e).on('click', handleClick);
    });
}
exports.default = default_1;
